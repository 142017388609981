<template>
  <vs-row class="gap-2">
    <!-- Form -->
    <vs-col vs-w="5">
      <div>
        <h4>Form</h4>
      </div>
      <div class="my-4">
        <div class="">
          <label class="vs-input--label">Invoice / Billing Code</label>
          <multiselect
            v-model="selectedBilling"
            :options="optionBillings"
            :custom-label="customLabelBilling"
            @select="onSelectBilling"
          />
        </div>
      </div>

      <div class="mb-2">
        <label class="vs-input--label">Remaining Value</label>
        <vs-input
          class="w-full"
          type="text"
          readonly
          :value="formatNumber(billingOutstanding)"
        />
      </div>

      <div class="mb-2">
        <label class="vs-input--label">Customer</label>
        <vs-input
          class="w-full"
          type="text"
          readonly
          :value="form.customer_name"
        />
      </div>

      <div class="mb-2">
        <label class="vs-input--label"> Payment Method </label>
        <multiselect
          v-model="selectedPaymentMethod"
          :options="optionPaymentMethods"
          :custom-label="customLabelPaymentMethod"
        />
      </div>

      <div class="mb-2">
        <label class="vs-input--label"> Total </label>
        <vs-input
          class="w-full"
          type="number"
          v-model="form.total"
          min="0"
          max="99999999"
        />
      </div>

      <div class="mb-2">
        <label class="vs-input--label"> Reason </label>
        <vs-textarea v-model="form.reason" placeholder="Reason" />
      </div>

      <div class="mb-2" v-show="isBankTransfer">
        <label class="vs-input--label"> Bank </label>
        <multiselect
          v-model="selectedBank"
          :options="optionBanks"
          :custom-label="customLabelBank"
          @search-change="onSearchBank"
          @select="onSelectBank"
        />

        <div class="mb-2">
          <label class="vs-input--label"> Transfer Date </label>
          <vs-input v-model="form.date_transfer" class="w-full" type="date" />
        </div>
      </div>

      <div v-show="isGiro" class="mb-2">
        <div>
          <label class="vs-input--label"> Giro Number </label>
          <vs-input
            v-model="form.nomor_giro"
            placeholder="Giro Number"
            class="w-full"
          />
        </div>

        <div class="mb-2">
          <label class="vs-input--label"> Giro Due Date </label>
          <vs-input v-model="form.date_giro" class="w-full" type="date" />
        </div>
      </div>

      <!-- upload file -->
      <div class="mb-2" v-show="isGiro || isBankTransfer">
        <label class="vs-input--label">Uplaod Photo proof of payment </label>
        <vs-input
          v-model="form.image"
          class="w-full"
          type="file"
          @change="onFileChange"
        />
      </div>

      <div class="my-4">
        <div class="flex items-center gap-2">
          <vs-button
            @click="onClickBtnAdd()"
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-plus"
            >Add</vs-button
          >
        </div>
      </div>
    </vs-col>
    <!-- Table -->
    <vs-col vs-w="6">
      <vs-table :data="listPayments">
        <template slot="header">
          <h3>Data New Payment</h3>
          <vs-button
            @click="onClickBtnSave()"
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-save"
            >Create Payment</vs-button
          >
        </template>
        <template slot="thead">
          <vs-th> Invoice Code </vs-th>
          <vs-th> Payment Method </vs-th>
          <vs-th> Payment Value </vs-th>
          <vs-th> Reason </vs-th>
          <vs-th> Action </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].billing_order_code">
              {{ data[indextr].billing_order_code }}
            </vs-td>
            <vs-td :data="data[indextr].payment_method_name">
              {{ data[indextr].payment_method_name }}
            </vs-td>

            <vs-td :data="data[indextr].total">
              {{ formatNumber(data[indextr].total) }}
            </vs-td>

            <vs-td :data="data[indextr].reason">
              {{ data[indextr].reason }}
            </vs-td>

            <vs-td>
              <vs-button
                @click="tableDelete(indextr)"
                size="small"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-col>
  </vs-row>
</template>

<script>
import { formatNumber } from "../../../utils/common";

export default {
  props: {
    collectionPlanLineIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      optionBillings: [],
      optionPaymentMethods: [],
      optionBanks: [],
      selectedBilling: null,
      selectedPaymentMethod: null,
      selectedBank: null,

      isCash: false,
      isBankTransfer: false,
      isGiro: false,

      form: {
        customer_order_id: null,
        customer_id: null,
        customer_name: null,
        company_id: null,
        payment_method_id: null,
        payment_method_name: null,
        description: "",
        billing_order_id: null,
        billing_order_code: null,
        date_transfer: null,
        image: "",
        status: 0,
        total: 0,
        reason: "",
        nomor_giro: "",
        bank_name: "",
        date_giro: "",
        promise_date: "",
        date: "",
        bank_id: null,
        time: null,
        territory_id: null,
        shipto_id: null,
        need_approval: true,
        from_hit: "backoffice",
        collection_plan_id: null,
        sales_personal_id: null,
      },
      imageBase64: "",
      billingTotal: 0,
      billingOutstanding: 0,
      listPayments: [],
    };
  },
  mounted() {
    this.getCollectionPlanLine({
      ids: this.collectionPlanLineIds,
    });
    this.getPaymentMethod();
    this.getBank();
  },
  watch: {
    selectedPaymentMethod(val) {
      if (val) {
        let name = val.name;
        if (val.code == "COD") {
          this.isCash = true;
          this.isBankTransfer = false;
          this.isGiro = false;
          name = "Cash";
        } else if (val.code == "TRF") {
          this.isCash = false;
          this.isBankTransfer = true;
          this.isGiro = false;
        } else if (val.code == "GR") {
          this.isCash = false;
          this.isBankTransfer = false;
          this.isGiro = true;
        }

        this.form.payment_method_name = name;
      }
    },
  },
  methods: {
    tableDelete(index) {
      this.listPayments.splice(index, 1);
    },
    onClickBtnAdd() {
      this.sanityForm();
      if (this.validationForm()) {
        return;
      }

      // set validation total each invoice code
      if (this.listPayments.length == 0) {
        this.listPayments.push(this.form);
        this.resetForm();
        return;
      } else {
        let groups = this.listPayments.reduce((groups, item) => {
          const val = item.billing_order_code;
          groups[val] = groups[val] || [];
          groups[val].push(item);
          return groups;
        }, {});

        // check if total of invoice code is more than outstanding
        let total = 0;
        if (groups[this.form.billing_order_code] != null) {
          groups[this.form.billing_order_code].forEach((item) => {
            total += item.total;
          });

          if (total + this.form.total > this.billingOutstanding) {
            this.$vs.notify({
              title: "Error",
              text:
                "Invoice code " +
                this.form.billing_order_code +
                " total must be less than or equal to outstanding",
              color: "danger",
            });
            return;
          }
        }

        this.listPayments.push(this.form);
        this.resetForm();
      }
    },
    formatNumber,
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.imageBase64 = reader.result;
      };
    },
    resetForm() {
      this.form = {
        customer_order_id: null,
        customer_id: null,
        customer_name: null,
        company_id: null,
        payment_method_id: null,
        payment_method_name: null,
        description: "",
        billing_order_id: null,
        billing_order_code: null,
        date_transfer: null,
        image: "",
        status: 0,
        total: 0,
        reason: "",
        nomor_giro: "",
        bank_name: "",
        date_giro: "",
        promise_date: "",
        date: "",
        bank_id: null,
        time: null,
        territory_id: null,
        shipto_id: null,
        need_approval: true,
        from_hit: "backoffice",
        collection_plan_id: null,
        sales_personal_id: null,
      };
      this.selectedBilling = null;
      this.selectedPaymentMethod = null;
      this.selectedBank = null;

      this.isCash = false;
      this.isBankTransfer = false;
      this.isGiro = false;
      this.billingTotal = 0;
      this.billingOutstanding = 0;
    },
    sanityForm() {
      this.form.total = parseFloat(this.form.total);
      if (this.selectedPaymentMethod) {
        this.form.payment_method_id = this.selectedPaymentMethod.id;
      }
      // 2021-09-01 00:00:00.000
      this.form.date = this.form.date + " 00:40:08.981457";
      if (this.form.reason == "") {
        this.form.reason = "-";
      }
      // remove data:image/png;base64,
      this.form.image = this.imageBase64.replace(
        /^data:image\/[a-z]+;base64,/,
        ""
      );
      // set collection plan id
      this.form.collection_plan_id = parseInt(this.$route.params.id);
    },
    validationForm(data) {
      if (this.selectedPaymentMethod == null) {
        this.$vs.notify({
          title: "Error",
          text: "Payment method must be selected",
          color: "danger",
        });
        return true;
      }
      // input total mandatory
      if (this.form.total == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Total must be filled",
          color: "danger",
        });
        return true;
      }

      if (this.form.total > this.billingOutstanding) {
        this.$vs.notify({
          title: "Error",
          text: "Total must be less than or equal to outstanding",
          color: "danger",
        });
        return true;
      }

      // if payment method is bank transfer, bank mandatory
      if (this.isBankTransfer) {
        if (!this.selectedBank) {
          this.$vs.notify({
            title: "Error",
            text: "Bank must be selected",
            color: "danger",
          });
          return true;
        }
        if (!this.form.date_transfer) {
          this.$vs.notify({
            title: "Error",
            text: "Transfer date must be filled",
            color: "danger",
          });
          return true;
        }
      }

      if (this.isBankTransfer || this.isGiro) {
        if (this.form.image == "") {
          this.$vs.notify({
            title: "Error",
            text: "Image must be uploaded",
            color: "danger",
          });

          return true;
        }
      }

      if (this.isGiro) {
        if (this.form.nomor_giro == "") {
          this.$vs.notify({
            title: "Error",
            text: "Number Giro must be filled",
            color: "danger",
          });
          return true;
        }

        if (this.form.date_giro == "") {
          this.$vs.notify({
            title: "Error",
            text: "Date Giro must be filled",
            color: "danger",
          });
          return true;
        }
      }

      return false;
    },
    onClickBtnSave() {
      let payload = {
        payment: this.listPayments,
      };

      this.$vs.loading();
      this.$http
        .post(`/api/sfa/v1/payment/customer-order-payment/create`, payload)
        .then((response) => {
          if (response.code == 200) {
            let data = response.data[0];
            if (data.resp_status == "Error") {
              let msg = data.status_create.message;
              this.$vs.notify({
                title: "Error",
                text: msg,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                title: "Success",
                text: "Success save data, please check on approval menu",
                color: "success",
              });
              this.listPayments = [];
            }
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Failed to save data",
            color: "danger",
          });
        });
    },
    getCollectionPlanLine(params) {
      this.$vs.loading();
      this.$http
        .get(`/api/sfa/v1/collection-plan/list-collection-plan-lines?`, {
          params: params,
        })
        .then((response) => {
          this.optionBillings = response.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Failed to get data",
            color: "danger",
          });
        });
    },
    onSelectBilling(data) {
      //   set form.customer_name
      this.form.customer_name = data.customer_name;
      this.form.customer_id = data.customer_id;
      this.form.shipto_id = data.ship_to_id;
      this.form.territory_id = data.customer_territory_id;
      this.form.billing_order_id = data.billing_order_id;
      this.form.customer_order_id = data.customer_order_id;
      this.form.billing_order_code = data.billing_order_code;
      this.form.company_id = this.$store.state.user.currentUser.company_id;
      // remove 2023-07-03T00:00:00Z
      this.form.date = data.collection_plan_date.replace(/T.+/, "");
      this.billingOutstanding = data.billing_outstanding;
      this.billingTotal = data.billing_total;
      this.form.sales_personal_id = data.sales_personal_id;
    },
    customLabelBilling(option) {
      return `${option.billing_order_code}`;
    },
    customLabelPaymentMethod(option) {
      if (option.code == "COD") {
        return `Cash`;
      }
      return `${option.name}`;
    },
    getPaymentMethod() {
      this.$http
        .get(`/api/sfa/v1/mobile-preparation/payment-method?is_mobile=true`)
        .then((response) => {
          this.optionPaymentMethods = response.data;
        });
    },
    getBank(params) {
      this.$http
        .get(`/api/sfa/v1/bank`, {
          params: params,
        })
        .then((response) => {
          this.optionBanks = response.data.records;
        });
    },
    onSearchBank(query) {
      this.getBank({
        search: query,
      });
    },
    onSelectBank(data) {
      this.form.bank_name = data.name;
      this.form.bank_id = data.id;
    },
    customLabelBank(option) {
      return `${option.name}`;
    },
  },
};
</script>
